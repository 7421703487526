<template>
    <b-overlay :show="isLoading">
        <b-card class="white-bg">
            <ValidationObserver ref="observer" v-slot="{ passes }">
                <b-form @submit.prevent="passes(saveBusinessOrder)" id="user-form">
                    <b-row class="mb-4">
                        <b-col cols="12" md="6">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Sucursal" invalid-feedback="Selecciona la sucursal" :state="errors[0] ? false : null">
                                    <v-select :options="branches" @search:focus="getBranches" @search="getBranches" v-model="businessOrder.branch"
                                              placeholder="Sucursal" label="name" :clearable="false">
                                        <template #no-options>Sin resultados.</template>
                                    </v-select>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="6" class="text-right">
                            <h1 class="font-weight-bolder">Reservación</h1>
                            <h1 class="text-navy font-weight-bolder">{{ businessOrder.folio }}</h1>
                            <h3>
                                <b-badge variant="warning" class="button-sticky-right" v-if="businessOrder.status === 'DRAFT'">Borrador</b-badge>
                                <b-badge variant="info" class="button-sticky-right" v-if="businessOrder.status === 'OPEN'">Abierta</b-badge>
                            </h3>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row class="mb-2">
                        <b-col cols="4">
                            <h5 class="mb-2">Cliente</h5>
                        </b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col cols="12" md="4">
                            <b-form-group label="Tipo">
                                <b-form-radio-group v-model="businessOrder.businessEntity.type" :options="businessEntityTypes"></b-form-radio-group>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col cols="12" sm="6" md="3">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group :label="(businessOrder.businessEntity.type === 'FISICA' ? 'Nombre(s)' : 'Razón social')" :invalid-feedback="businessOrder.businessEntity.type === 'FISICA' ? 'Ingresa el nombre(s)' : 'Ingresa la razón social'"
                                              :state="errors[0] ? false : null">
                                    <vue-typeahead-bootstrap v-model="businessEntityQuery" :data="businessEntities"
                                                             :placeholder="businessOrder.businessEntity.type === 'FISICA' ? 'Nombre(s)' : 'Razón social'"
                                                             @input="searchBusinessEntity" :serializer="item => (item.type === 'FISICA' ? item.name : item.legalName)"
                                                             @hit="selectBusinessEntity" @keyup.enter="selectBusinessEntity" show-all-results
                                                             :class="[errors[0] ? 'has-error' : '', 'default-border-radius']">
                                        <template #suggestion="{ data }">
                                            <p class="mb-0">{{ data.customerName }}</p>
                                            <p class="mb-0"><strong>Nombre comercial: </strong>{{ data.commercialName ? data.commercialName : 'Sin registro' }}</p>
                                            <p class="mb-0"><strong>RFC: </strong>{{ data.rfc ? data.rfc : 'Sin registro' }}</p>
                                            <p class="mb-0"><strong>Tipo: </strong>{{ data.type === 'FISICA' ? 'Fisica' : 'Moral' }}</p>
                                        </template>
                                    </vue-typeahead-bootstrap>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <template v-if="businessOrder.businessEntity.type === 'MORAL'">
                            <b-col cols="12" sm="6" md="3">
                                <b-form-group label="Régimen capital">
                                    <b-form-input v-model="businessOrder.businessEntity.legalEntityType" autocomplete="false" placeholder="Régimen capital"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="6" md="3">
                                <b-form-group label="RFC">
                                    <b-form-input v-model="businessOrder.businessEntity.rfc" autocomplete="false" placeholder="RFC"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </template>
                        <template v-else>
                            <b-col cols="12" sm="6" md="3" class="text-left">
                                <b-form-group label="Apellido paterno">
                                    <b-form-input v-model="businessOrder.businessEntity.firstLastName" autocomplete="false" placeholder="Apellido paterno"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="6" md="3" class="text-left" key="businessEntitySecondLastName">
                                <b-form-group label="Apellido materno">
                                    <b-form-input v-model="businessOrder.businessEntity.secondLastName" autocomplete="false" placeholder="Apellido materno"></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" sm="6" md="3">
                                <b-form-group label="RFC">
                                    <b-form-input v-model="businessOrder.businessEntity.rfc" autocomplete="false" placeholder="RFC"></b-form-input>
                                </b-form-group>
                            </b-col>
                        </template>
                    </b-row>
                    <b-row>
                        <b-col cols="12" md="6" class="text-left">
                            <ValidationProvider :rules="businessOrder.businessEntity.type === 'MORAL' ? 'required' : ''" v-slot="{ errors }">
                                <b-form-group label="Nombre comercial" invalid-feedback="Ingresa el nombre comercial">
                                    <b-form-input v-model="businessOrder.businessEntity.commercialName" autocomplete="false" placeholder="Nombre comercial"
                                                  :state="errors[0] ? false : null" @input="setManuallyEditedFlag"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row v-if="businessOrder.businessEntity.type === 'MORAL'">
                        <b-col cols="12" sm="6" md="3" class="text-left">
                            <b-form-group label="Nombre(s) del contacto">
                                <b-form-input v-model="businessOrder.businessEntity.name" autocomplete="false" placeholder="Nombre(s)"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6" md="3" class="text-left">
                            <b-form-group label="Apellido paterno del contacto">
                                <b-form-input v-model="businessOrder.businessEntity.firstLastName" autocomplete="false" placeholder="Apellido paterno"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6" md="3" class="text-left" key="businessEntitySecondLastName">
                            <b-form-group label="Apellido materno del contacto">
                                <b-form-input v-model="businessOrder.businessEntity.secondLastName" autocomplete="false" placeholder="Apellido materno"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" sm="6" md="3">
                            <b-form-group label="Teléfono 1">
                                <b-form-input v-model="businessOrder.businessEntity.phone1" autocomplete="false" placeholder="Teléfono 1"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6" md="3">
                            <b-form-group label="Teléfono 2">
                                <b-form-input v-model="businessOrder.businessEntity.phone2" autocomplete="false" placeholder="Teléfono 2"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" sm="6" md="3">
                            <ValidationProvider rules="email" v-slot="{ errors }">
                                <b-form-group label="Email 1" invalid-feedback="Ingresa un email válido">
                                    <b-form-input v-model="businessOrder.businessEntity.email1" autocomplete="false" placeholder="Email 1"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" sm="6" md="3">
                            <ValidationProvider rules="email" v-slot="{ errors }">
                                <b-form-group label="Email 2" invalid-feedback="Ingresa un email válido">
                                    <b-form-input v-model="businessOrder.businessEntity.email2" autocomplete="false" placeholder="Email 2"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <hr>
                    <h5>Pasajeros</h5>
                    <b-row>
                        <b-col cols="6" sm="3" lg="2">
                            <b-input-group>
                                <b-form-input type="number" no-wheel min="0" v-model="businessOrder.adultsQty" :disabled="businessOrder.status === 'OPEN'" @change="onAdultsQtyChanged"></b-form-input>
                                <template #prepend>
                                    <b-input-group-text>Adultos</b-input-group-text>
                                </template>
                            </b-input-group>
                        </b-col>
                        <b-col cols="6" sm="3" lg="2">
                            <b-input-group>
                                <b-form-input  type="number" no-wheel min="0" v-model="businessOrder.childrenQty" :disabled="businessOrder.status === 'OPEN'" @change="onChildrenQtyChanged"></b-form-input>
                                <template #prepend>
                                    <b-input-group-text>Niños</b-input-group-text>
                                </template>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <b-row class="mt-4">
                        <b-col>
                            <b-table-simple responsive bordered striped>
                                <b-thead>
                                    <b-tr>
                                        <b-th class="text-center" style="width: 8%">Pasajero</b-th>
                                        <b-th style="width: 8%">Tipo</b-th>
                                        <b-th>Nombre(s)</b-th>
                                        <b-th>Apellido(s)</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr v-for="(passenger, index) in businessOrder.passengers" :key="passenger.guid">
                                        <b-td class="text-center">{{ index + 1 }}</b-td>
                                        <b-td>{{ passenger.type === 'ADULT' ? 'Adulto' : 'Niño' }}</b-td>
                                        <b-td style="min-width: 125px">
                                            <b-form-input v-model="passenger.name" autocomplete="false" placeholder="Nombre(s)" @input="updatePassengerData(passenger)"></b-form-input>
                                        </b-td>
                                        <b-td style="min-width: 125px">
                                            <b-form-input v-model="passenger.firstLastName" autocomplete="false" placeholder="Apellidos" @input="updatePassengerData(passenger)"></b-form-input>
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </b-col>
                    </b-row>
                    <hr>
<!--                    <h5>Pasajeros</h5>-->
                    <b-row>
                        <b-col cols="12" sm="6" md="3">
                            <b-form-group label="Folio del cliente (agencia)">
                                <b-form-input v-model="businessOrder.customerFolio" autocomplete="false" placeholder="Folio del cliente (agencia)"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <hr>
                    <h5 class="mb-2 float-left">Partidas</h5>
                    <b-button class="button-sticky-right" @click="addRow"><font-awesome-icon icon="plus"></font-awesome-icon> Nueva</b-button>
                    <b-row class="mt-4" v-if="businessOrder.businessOrderItems.length > 0">
                        <b-col>
                            <b-table-simple responsive bordered striped>
                                <b-thead>
                                    <b-tr>
                                        <b-th class="text-center" style="width: 40px">#</b-th>
                                        <b-th>Concepto</b-th>
                                        <b-th style="width: 8%" class="text-right">Cantidad</b-th>
                                        <b-th style="width: 11%" class="text-right">Precio Unitario</b-th>
                                        <b-th style="width: 11%" class="text-right">Subtotal</b-th>
                                        <b-th class="text-center">Acciones</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr v-for="(item, index) in businessOrder.businessOrderItems" :key="item.guid" :variant="item.isDeleted() ? 'danger' : ''">
                                        <b-td :class="['text-center', { 'text-danger': item.isDeleted() }, { 'text-danger': item.isCancel() }]">{{ index + 1 }} {{ item.isDeleted() ? 'Eliminado' : '' }} {{ item.isCancel() ? 'Cancelado' : '' }}</b-td>
                                        <b-td>
                                            <b-row>
                                                <b-col>
                                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                                        <b-form-group invalid-feedback="Selecciona un servicio" class="mt-0" :state="errors[0] ? false : null">
                                                            <v-select :options="services" @search:focus="getServices" @search="getServices" @input="setBusinessOrderItemService(item, $event)"
                                                                      placeholder="Servicio" label="name" append-to-body :clearable="false" v-model="item.service">
                                                                <template #no-options>Sin resultados.</template>
                                                            </v-select>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                            <b-row class="mt-2" v-if="item.service">
                                                <b-col md="6">
                                                    <strong>{{ item.entityType === 'TOUR' ? 'Tour' : 'Traslado' }}: </strong> {{ item.name }}<br>
                                                    <template v-if="item.entityType === 'TOUR'"><strong>Duración: </strong>{{ item.duration }} Día(s)</template>
                                                </b-col>
                                                <template v-if="item.service.isCollective && item.service.isExclusive">
                                                    <b-col md="6">
                                                        <b-form-group label="Tipo">
                                                            <b-form-radio-group v-model="item.type" :options="serviceTypes" @input="updateItemTotal(item)"></b-form-radio-group>
                                                        </b-form-group>
                                                    </b-col>
                                                </template>
                                                <template v-else>
                                                    <b-col md="6">
                                                        <strong>Tipo: </strong>{{ item.type === 'COLLECTIVE' ? 'Colectivo' : 'Exclusivo' }}
                                                    </b-col>
                                                </template>
                                            </b-row>
                                            <b-row class="mt-2" v-if="item.service">
                                                <b-col md="3" class="position-static">
                                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                                        <b-form-group :label="(item.entityType === '' || item.entityType === 'TOUR' ? 'Fecha de inicio' : 'Fecha')" invalid-feedback="Selecciona una fecha de inicio" :state="errors[0] ? false : null">
                                                            <b-form-datepicker v-model="item.startDate" boundary="window" :placeholder="(item.entityType === '' || item.entityType === 'TOUR' ? 'Fecha de inicio' : 'Fecha')"
                                                                               v-bind="datePickerLabels" locale="es" hide-header @input="itemStartDateChange(item)"></b-form-datepicker>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col md="3" class="position-static" v-if="item.entityType === '' || item.entityType === 'TOUR'">
                                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                                        <b-form-group label="Fecha de término" invalid-feedback="Selecciona una fecha de término" :state="errors[0] ? false : null">
                                                            <b-form-datepicker v-model="item.endDate" boundary="window" placeholder="Fecha de término"
                                                                               v-bind="datePickerLabels" locale="es" hide-header disabled></b-form-datepicker>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <template v-if="item.entityType === 'TRANSFER'">
                                                    <b-col md="3">
                                                        <ValidationProvider :rules="{required: true, regex: /\d\d:\d\d/ }" v-slot="{ errors }">
                                                            <b-form-group label="Horario de vuelo" :state="errors[0] ? false : null"
                                                                          :invalid-feedback="item.flightSchedule.trim() === '' ? 'Ingresa el horario de vuelo' : 'Ingresa un horario de vuelo válido'">
                                                                <b-form-input autocomplete="false" placeholder="Horario de vuelo" v-model="item.flightSchedule" data-inputmask="'alias': 'datetime', 'inputFormat': 'HH:MM'" class="inputmask"></b-form-input>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col md="3">
                                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                                            <b-form-group label="Número de vuelo" invalid-feedback="Ingresa un número de vuelo" :state="errors[0] ? false : null">
                                                                <b-form-input autocomplete="false" placeholder="Número de vuelo" v-model="item.flightNumber"></b-form-input>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col md="3">
                                                        <ValidationProvider rules="required" v-slot="{ errors }">
                                                            <b-form-group label="Hotel" invalid-feedback="Selecciona un hotel" :state="errors[0] ? false : null">
                                                                <vue-typeahead-bootstrap v-model="item.hotel1" :data="hotels" placeholder="Hotel"
                                                                                         @input="searchHotel(item.hotel1)" :serializer="hotel => hotel.name">
                                                                </vue-typeahead-bootstrap>
                                                            </b-form-group>
                                                        </ValidationProvider>
                                                    </b-col>
                                                </template>
                                                <b-col md="3" v-if="item.entityType === 'TOUR'">
                                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                                        <b-form-group label="Recoger en hotel" invalid-feedback="Selecciona un hotel" :state="errors[0] ? false : null">
                                                            <vue-typeahead-bootstrap v-model="item.hotel1" :data="hotels" placeholder="Recoger en hotel"
                                                                                     @input="searchHotel(item.hotel1)" :serializer="hotel => hotel.name" @hit="item.hotel2 = $event.name">
                                                            </vue-typeahead-bootstrap>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col md="3" v-if="item.entityType === 'TOUR'">
                                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                                        <b-form-group label="Dejar en hotel" invalid-feedback="Selecciona un hotel" :state="errors[0] ? false : null">
                                                            <vue-typeahead-bootstrap v-model="item.hotel2" :data="hotels" placeholder="Dejar en hotel"
                                                                                     @input="searchHotel(item.hotel2)" :serializer="hotel => hotel.name">
                                                            </vue-typeahead-bootstrap>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                            <b-row class="mt-2">
                                                <b-col md="12">
                                                    <b-form-group label="Notas">
                                                        <b-form-textarea placeholder="Notas" v-model="item.notes"></b-form-textarea>
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>
                                            <b-row class="mt-2">
                                                <b-col>
                                                    <b-table-simple responsive bordered striped>
                                                        <b-thead>
                                                            <b-tr>
                                                                <b-th colspan="2">
                                                                    Pasajeros
                                                                    <span class="float-right mb-0">
                                                                        <span class="mr-2">Todos</span>
                                                                        <b-form-checkbox switch class="float-right" v-model="item.allPassengers"
                                                                                         :disabled="!['CREATED', 'DRAFT', 'TO_ASSIGN'].includes(item.status)"
                                                                                         @input="itemAllSelectedChange(item)"></b-form-checkbox>
                                                                    </span>
                                                                </b-th>
                                                            </b-tr>
                                                            <b-tr v-if="!item.allPassengers">
                                                                <b-th style="width: 10%"></b-th>
                                                                <b-th>Nombre</b-th>
                                                            </b-tr>
                                                        </b-thead>
                                                        <b-tbody v-if="!item.allPassengers">
                                                            <b-tr v-for="passenger in item.passengers" :key="passenger.guid">
                                                                <td>
                                                                    <b-form-checkbox v-model="passenger.selected"
                                                                                     :disabled="!['CREATED', 'DRAFT', 'TO_ASSIGN'].includes(item.status)"
                                                                                     @input="passengerSelectedChange(passenger.selected, item)"></b-form-checkbox>
                                                                </td>
                                                                <td>{{ passenger.name }} {{ passenger.firstLastName }} {{ passenger.secondLastName }} <span class="text-danger" v-if="passenger.cancel">(Cancelado)</span></td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>
                                            </b-row>
                                            <template v-if="item.status == 'CANCEL'">
                                                <b-row class="mt-2">
                                                    <b-col md="12">
                                                        <label>Motivo de la cancelación</label>
                                                        <p>{{ item.cancelDescription }}</p>
                                                    </b-col>
                                                </b-row>
                                            </template>
                                        </b-td>
                                        <b-td class="text-right">
                                            <b-row v-if="item.service">
                                                <b-col md="12">
                                                    <template v-if="item.adults && item.type === 'COLLECTIVE'">
                                                        <span class="font-weight-bold">Adultos: </span>{{ item.adults }}<br>
                                                    </template>
                                                    <template v-if="item.children && item.type === 'COLLECTIVE'">
                                                        <span class="font-weight-bold">Niños: </span>{{ item.children }}
                                                    </template>
                                                    <template v-if="item.type === 'EXCLUSIVE'">1</template>
                                                </b-col>
                                            </b-row>
                                        </b-td>
                                        <b-td class="text-right">
                                            <b-row v-if="item.service">
                                                <b-col md="12">
                                                    <template v-if="item.adults && item.type === 'COLLECTIVE'">
                                                        <span class="font-weight-bold">Adulto: </span>$ {{ formatCurrency(item.adultsFare) }}<br>
                                                    </template>
                                                    <template v-if="item.children && item.type === 'COLLECTIVE'">
                                                        <span class="font-weight-bold">Niño: </span>$ {{ formatCurrency(item.childFare) }}
                                                    </template>
                                                    <template v-if="item.type === 'EXCLUSIVE'">
                                                        <span class="font-weight-bold">Servicio: </span>$ {{ formatCurrency(item.serviceFare) }}
                                                    </template>
                                                </b-col>
                                            </b-row>
                                        </b-td>
                                        <b-td class="text-right">
                                            <b-row>
                                                <b-col md="12">
                                                    <template v-if="item.adults && item.type === 'COLLECTIVE'">
                                                        $ {{ formatCurrency(item.adultsFare * item.adults) }}<br>
                                                    </template>
                                                    <template v-if="item.children && item.type === 'COLLECTIVE'">
                                                        $ {{ formatCurrency(item.childFare * item.children) }}<br>
                                                    </template>
                                                    <span class="font-weight-bold">Subtotal: </span>$ {{ formatCurrency(item.total) }}
                                                </b-col>
                                            </b-row>
                                        </b-td>
                                        <b-td class="text-center" style="width: 80px">
                                            <b-button-group v-if="!item.isDeleted()">
                                                <b-button variant="outline-danger" @click="deleteRow(item)" v-b-tooltip.hover.top title="Eliminar">
                                                    <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                                                </b-button>
                                                <b-button variant="outline-primary" @click="cloneRow(item, index)" v-b-tooltip.hover.top title="Duplicar">
                                                    <font-awesome-icon icon="clone"></font-awesome-icon>
                                                </b-button>
                                            </b-button-group>
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                                <b-tfoot>
                                    <b-tr>
                                        <b-th colspan="4" class="text-right">Total</b-th>
                                        <b-th class="text-right">$ {{ formatCurrency(businessOrder.total) }}</b-th>
                                        <b-th></b-th>
                                    </b-tr>
                                </b-tfoot>
                            </b-table-simple>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>
            <template #footer>
                <div class="text-right">
                    <b-button variant="outline-secondary" :to="{ name: 'BusinessOrderIndex' }" class="mr-2"><font-awesome-icon icon="angle-left"></font-awesome-icon> Regresar</b-button>
                    <b-button variant="secondary" :to="{ name: 'BusinessOrderShow', params: { id: businessOrder.id }}" v-if="businessOrder.id" class="mr-2">
                        <font-awesome-icon icon="search"></font-awesome-icon> Detalle
                    </b-button>
                    <b-button @click="validateForm(true)" variant="primary" class="mr-2" v-if="businessOrder.status === 'DRAFT'"><font-awesome-icon icon="sync"></font-awesome-icon> Procesar</b-button>
                    <b-button @click="validateForm(true)" variant="primary" class="mr-2" v-if="businessOrder.status === 'OPEN'"><font-awesome-icon icon="check"></font-awesome-icon> Cerrar</b-button>
                    <b-button @click="validateForm(false)" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Guardar</b-button>
                </div>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>
import { extend } from 'vee-validate';
import { required, email, numeric, regex } from 'vee-validate/dist/rules';
import { businessOrderUrl, branchSelectUrl, serviceSelectUrl, hotelSelectUrl } from '@routes';
import * as constants from '@constants';
import { Passenger } from '../../models/passenger';
import { BusinessOrderItem } from '../../models/businessOrderItem';
import { BusinessEntity } from '../../models/businessEntity';
import { isZeroOrPositive, formatCurrency } from '../../js/utilities';
import { datePickerLabels } from '../../i18n/datePickerI18n';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import { debounce } from 'lodash';
import { businessEntitySelectUrl } from '../../js/routes';
const dayjs = require('dayjs');
import Inputmask from 'inputmask';

extend('required', {
    ...required,
    message: 'Campo requerido'
});

extend('email', {
    ...email,
    message: 'Ingresa un email válido'
});

extend('numeric', {
    ...numeric,
    message: 'Ingresa solo números'
});

extend('regex', {
    ...regex,
    message: 'Ingresa un valor válido'
});

export default {
    data() {
        return {
            businessOrder: {
                id: 0,
                folio: '',
                branch: null,
                businessEntity: BusinessEntity.create(),
                adultsQty: 0,
                childrenQty: 0,
                adultsFare: 0,
                childFare: 0,
                total: 0,
                notes: '',
                businessOrderItems: [],
                passengers: [],
                status: 'DRAFT',
                customerFolio: ''
            },
            businessEntityTypes: [
                { text: 'Persona física', value: 'FISICA' },
                { text: 'Persona moral', value: 'MORAL' }
            ],
            serviceTypes: [
                { text: 'Colectivo', value: 'COLLECTIVE' },
                { text: 'Exclusivo', value: 'EXCLUSIVE' }
            ],
            branches: [],
            services: [],
            passengers: {
                adults: [],
                children: []
            },
            swalTitle: 'Reservaciones',
            breadcrumb: {
                title: 'Reservaciones',
                path: [
                    {
                        name: 'BusinessOrderIndex',
                        text: 'Reservaciones'
                    }
                ]
            },
            action: 'Nueva',
            processOrder: false,
            isLoading: false,
            datePickerLabels: datePickerLabels.es,
            businessEntityQuery: '',
            businessEntities: [],
            hotels: [],
            isCommercialNameManuallyEdited: false
        };
    },
    components: {
        VueTypeaheadBootstrap
    },
    created() {
        if (this.$route.name.includes('Edit')) {
            this.loadData();

            this.action = 'Modificar';
        }

        this.breadcrumb.path.push({
            name: this.$route.name,
            text: `${this.action}`
        });

        this.$emit('update-breadcrumb', this.breadcrumb);
    },
    methods: {
        formatCurrency: formatCurrency,
        loadData() {
            this.isLoading = true;

            this.axios.get(businessOrderUrl(this.$route.params.id)).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.businessOrder = response.data.businessOrder;

                    if (this.businessOrder.status === 'CLOSED') {
                        this.$router.push({ name: 'BusinessOrderShow', params: { id: this.businessOrder.id.toString() }});
                    }

                    if (this.businessOrder.businessEntity.type === 'FISICA') {
                        this.businessEntityQuery = this.businessOrder.businessEntity.name;
                    } else {
                        this.businessEntityQuery = this.businessOrder.businessEntity.legalName;
                    }

                    this.isCommercialNameManuallyEdited = true;

                    response.data.passengers.forEach(passengerData => {
                        let passenger = Passenger.create(passengerData.type);

                        passenger.id = passengerData.id;
                        passenger.name = passengerData.name;
                        passenger.firstLastName = passengerData.firstLastName;
                        passenger.secondLastName = passengerData.secondLastName;
                        passenger.identifier = passengerData.id.toString();

                        this.businessOrder.passengers.push(passenger);

                        if (passenger.type === 'ADULT') {
                            this.passengers.adults.push(passenger);
                        } else {
                            this.passengers.children.push(passenger);
                        }
                    });

                    response.data.businessOrderItems.forEach(businessOrderItemData => {
                        let businessOrderItem = BusinessOrderItem.create();

                        businessOrderItem.id = businessOrderItemData.id;
                        businessOrderItem.name = businessOrderItemData.name;
                        businessOrderItem.description = businessOrderItemData.description;
                        businessOrderItem.type = businessOrderItemData.type;
                        businessOrderItem.adults = businessOrderItemData.adults;
                        businessOrderItem.adultsFare = businessOrderItemData.adultsFare;
                        businessOrderItem.children = businessOrderItemData.children;
                        businessOrderItem.childFare = businessOrderItemData.childFare;
                        businessOrderItem.serviceFare = businessOrderItemData.serviceFare;
                        businessOrderItem.total = businessOrderItemData.total;
                        businessOrderItem.startDate = businessOrderItemData.startDate;
                        businessOrderItem.endDate = businessOrderItemData.endDate;
                        businessOrderItem.hotel1 = businessOrderItemData.hotel1;
                        businessOrderItem.hotel2 = businessOrderItemData.hotel2;
                        businessOrderItem.flightNumber = businessOrderItemData.flightNumber;
                        businessOrderItem.flightSchedule = businessOrderItemData.flightSchedule;
                        businessOrderItem.notes = businessOrderItemData.notes;
                        businessOrderItem.cancelDescription = businessOrderItemData.cancelDescription;
                        businessOrderItem.status = businessOrderItemData.status;
                        businessOrderItem.passengers = [];
                        businessOrderItem.allPassengers = false;
                        businessOrderItem.service = {
                            id: businessOrderItemData.serviceId,
                            name: businessOrderItemData.name,
                            isCollective: businessOrderItemData.serviceCollective,
                            isExclusive: businessOrderItemData.serviceExclusive
                        };
                        businessOrderItem.duration = businessOrderItemData.duration;
                        businessOrderItem.entityType = businessOrderItemData.entityType;

                        let businessOrderItemPassengers = JSON.parse(businessOrderItemData.passengersData);

                        businessOrderItemPassengers.forEach(passengerData => {
                            let passenger = Passenger.create(passengerData.type);

                            passenger.id = passengerData.id;
                            passenger.name = passengerData.name;
                            passenger.firstLastName = passengerData.firstLastName;
                            passenger.secondLastName = passengerData.secondLastName;
                            passenger.identifier = passengerData.id.toString();
                            passenger.selected = passengerData.selected;
                            passenger.assigned = passengerData.assigned;
                            passenger.cancel = passengerData.cancel;
                            passenger.serviceOrderId = passengerData.serviceOrderId;

                            businessOrderItem.passengers.push(passenger);
                        });

                        let selectedPassengers = businessOrderItem.passengers.filter(passenger => passenger.selected);

                        if (selectedPassengers.length === this.businessOrder.passengers.length) {
                            businessOrderItem.allPassengers = true;
                        }

                        this.businessOrder.businessOrderItems.push(businessOrderItem);
                    });
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        getBranches(search, loading) {
            if (loading) {
                loading(true);
            }

            this.axios.get(branchSelectUrl(), { params: { search }}).then(response => {
                this.branches = response.data;
            }).catch(() => {
                this.$bvToast.toast('Ocurrió un error al listar las sucursales.', {
                    title: this.swalTitle,
                    variant: 'danger'
                });

                this.branches = [];
            }).finally(() => {
                if (loading) {
                    loading(false);
                }
            });
        },
        getServices(search, loading) {
            if (loading) {
                loading(true);
            }

            this.axios.get(serviceSelectUrl(), {
                params: {
                    search,
                    businessEntityId: this.businessOrder.businessEntity.id
                }
            }).then(response => {
                this.services = response.data;
            }).catch(() => {
                this.$bvToast.toast('Ocurrió un error al listar los servicios.', {
                    title: this.swalTitle,
                    variant: 'danger'
                });

                this.services = [];
            }).finally(() => {
                if (loading) {
                    loading(false);
                }
            });
        },
        selectBusinessEntity(businessEntity) {
            this.businessOrder.businessEntity = businessEntity;
        },
        onAdultsQtyChanged() {
            if (isZeroOrPositive(this.businessOrder.adultsQty)) {
                let newQty = this.businessOrder.adultsQty;
                let oldQty = this.passengers.adults.length;

                newQty = (newQty === '' ? 0 : newQty);

                if (newQty > oldQty) {
                    let count = oldQty;

                    while (count++ < newQty) {
                        this.passengers.adults.push(Passenger.create('ADULT'));
                    }
                } else {
                    let count = oldQty - newQty;

                    while (count-- > 0) {
                        this.passengers.adults.pop();
                    }
                }

                this.businessOrder.passengers = this.passengers.adults.concat(this.passengers.children);
                this.setDefaultPassengerNames();
                this.updateItemsPassenger();
            }
        },
        onChildrenQtyChanged() {
            if (isZeroOrPositive(this.businessOrder.childrenQty)) {
                let newQty = this.businessOrder.childrenQty;
                let oldQty = this.passengers.children.length;

                newQty = (newQty === '' ? 0 : newQty);

                if (newQty > oldQty) {
                    let count = oldQty;

                    while (count++ < newQty) {
                        this.passengers.children.push(Passenger.create('CHILD'));
                    }
                } else {
                    let count = oldQty - newQty;

                    while (count-- > 0) {
                        this.passengers.children.pop();
                    }
                }

                this.businessOrder.passengers = this.passengers.adults.concat(this.passengers.children);
                this.setDefaultPassengerNames();
                this.updateItemsPassenger();
            }
        },
        setDefaultPassengerNames() {
            for (let i = 0; i < this.businessOrder.passengers.length; i++) {
                if (this.businessOrder.passengers[i].name.trim() === '' ||
                    this.businessOrder.passengers[i].name.startsWith(constants.DEFAULT_PASSENGER_NAME)) {

                    this.businessOrder.passengers[i].name = `${constants.DEFAULT_PASSENGER_NAME} ${i+1}`;
                }
            }
        },
        addRow() {
            let passengers = this.businessOrder.passengers.map(passenger => passenger.clone());
            this.businessOrder.businessOrderItems.push(BusinessOrderItem.create(passengers));
        },
        deleteRow(item) {
            if (item.id > 0) {
                item.status = 'DELETED';
            } else {
                let index = this.businessOrder.businessOrderItems.findIndex(businessOrderItem => businessOrderItem.guid === item.guid);

                if (index >= 0) {
                    this.businessOrder.businessOrderItems.splice(index, 1);
                }
            }

            this.businessOrder.total = 0;

            this.businessOrder.businessOrderItems.forEach(item => {
                if (item.status !== 'DELETED') {
                    this.businessOrder.total = parseFloat(this.businessOrder.total) + parseFloat(item.total);
                }
            });
        },
        cloneRow(item, index) {
            let passengers = this.businessOrder.passengers.map(passenger => passenger.clone());

            this.businessOrder.businessOrderItems.splice(index + 1, 0, item.clone(passengers));
        },
        setBusinessOrderItemService(item, service) {
            item.name = service.name;
            item.description = service.description;
            item.isCollective = service.isCollective;
            item.isExclusive = service.isExclusive;
            item.adultsFare = service.adultFare;
            item.childFare = service.childrenFare;
            item.serviceFare = service.serviceFare;
            item.duration = service.duration;
            item.entityType = service.entityType;
            item.startDate = '';
            item.endDate = '';
            item.hotel1 = '';
            item.hotel2 = '';
            item.service = {
                id: item.service.id,
                name: item.service.name,
                isCollective: service.isCollective,
                isExclusive: service.isExclusive
            };

            if (!service.isCollective && service.isExclusive) {
                item.type = 'EXCLUSIVE';
            } else {
                item.type = 'COLLECTIVE';
            }

            this.updateItemTotal(item);

            this.$nextTick(() => {
                Inputmask().mask(document.querySelectorAll('.inputmask'));
            });
        },
        itemStartDateChange(item) {
            item.endDate = dayjs(item.startDate).add(parseInt(item.duration) - 1, 'day').format('YYYY-MM-DD');
        },
        validateForm(process) {
            this.$refs.observer.validate().then(success => {
                if (success) {
                    let validatePassengers = true;
                    this.businessOrder.businessOrderItems.forEach(item => {
                        let selectedPassengers = item.passengers.filter(passenger => passenger.selected);

                        if (selectedPassengers.length < 1) {
                            validatePassengers = false;
                        }
                    });

                    if (validatePassengers || !process) {
                        this.processOrder = process;
                        this.saveBusinessOrder();
                    } else {
                        this.$bvToast.toast('Se debe seleccionar al menos un pasajero por partida', {
                            variant: 'danger'
                        });
                    }
                }
            });
        },
        saveBusinessOrder() {
            if (this.processOrder) {
                let title = '¿Procesar la reservación?';

                if (this.businessOrder.status == 'OPEN') {
                    title = '¿Cerrar la reservación?';
                }

                if (this.businessOrder.businessEntity.type === 'FISICA') {
                    this.businessOrder.businessEntity.name = this.businessEntityQuery;
                } else {
                    this.businessOrder.businessEntity.legalName = this.businessEntityQuery;
                }

                this.$swal({
                    title: title,
                    icon: 'question'
                }).then(result => {
                    if (result.value) {
                        let oldStatus = this.businessOrder.status;

                        this.isLoading = true;

                        if (this.businessOrder.status == 'DRAFT') {
                            this.businessOrder.status = 'OPEN';
                        } else {
                            this.businessOrder.status = 'CLOSED';
                        }

                        this.axios.post(businessOrderUrl(), this.businessOrder).then(response => {
                            if (response.data.code === constants.CODE_OK) {
                                this.$root.$bvToast.toast(response.data.message, {
                                    title: this.swalTitle,
                                    variant: 'success'
                                });

                                this.$router.push({ name: 'BusinessOrderShow', params: { id: response.data.id }});
                            } else {
                                this.businessOrder.status = oldStatus;
                                this.$bvToast.toast(response.data.message, {
                                    title: this.swalTitle,
                                    variant: 'danger'
                                });
                            }
                        }).catch(() => {
                            this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                                title: this.swalTitle,
                                variant: 'danger'
                            });
                        }).finally(() => {
                            this.isLoading = false;
                        });
                    }
                });
            } else {
                if (this.businessOrder.businessEntity.type === 'FISICA') {
                    this.businessOrder.businessEntity.name = this.businessEntityQuery;
                } else {
                    this.businessOrder.businessEntity.legalName = this.businessEntityQuery;
                }

                this.axios.post(businessOrderUrl(), this.businessOrder).then(response => {
                    if (response.data.code === constants.CODE_OK) {
                        this.$root.$bvToast.toast(response.data.message, {
                            title: this.swalTitle,
                            variant: 'success'
                        });

                        this.$router.push({ name: 'BusinessOrderShow', params: { id: response.data.id }});
                    } else {
                        this.$bvToast.toast(response.data.message, {
                            title: this.swalTitle,
                            variant: 'danger'
                        });
                    }
                }).catch(() => {
                    this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                        title: this.swalTitle,
                        variant: 'danger'
                    });
                }).finally(() => {
                    this.isLoading = false;
                });
            }
        },
        searchBusinessEntity: debounce(function() {
            let $this = this;

            $this.axios.get(businessEntitySelectUrl(), { params: {
                search: $this.businessEntityQuery,
                isCustomer: true
            }}).then(response => {
                $this.businessEntities = response.data;
            }).catch(() => {
                $this.businessEntities = [];
            });
        }, 200),
        searchHotel: debounce(function(searchQuery) {
            let $this = this;

            $this.axios.get(hotelSelectUrl(), { params: {
                search: searchQuery
            }}).then(response => {
                if (response.data.code === 'OK') {
                    $this.hotels = response.data.data;
                }
            }).catch(() => {
                $this.hotels = [];
            });
        }, 200),
        updatePassengerData(passenger) {
            this.businessOrder.businessOrderItems.forEach(item => {
                let passengerFound = item.passengers.find(object => object.identifier === passenger.identifier);

                if (passengerFound) {
                    passengerFound.name = passenger.name;
                    passengerFound.firstLastName = passenger.firstLastName;
                    passengerFound.secondLastName = passenger.secondLastName;
                }
            });
        },
        updateItemsPassenger() {
            this.businessOrder.businessOrderItems.forEach(item => {
                let notSelectedPassengers = [];

                if (!item.allPassengers) {
                    notSelectedPassengers = item.passengers.filter(passenger => !passenger.selected).map(passenger => passenger.identifier);
                }

                item.passengers = this.businessOrder.passengers.map(passenger => passenger.clone());

                notSelectedPassengers.forEach(identifier => {
                    let notSelectedPassenger = item.passengers.find(passenger => passenger.identifier === identifier);

                    if (notSelectedPassenger) {
                        notSelectedPassenger.selected = false;
                    }
                });

                this.updateItemTotal(item);
            });
        },
        itemAllSelectedChange(item) {
            item.passengers.forEach(passenger => passenger.selected = item.allPassengers);

            this.updateItemTotal(item);
        },
        passengerSelectedChange(selected, item) {
            if (selected) {
                let selectedPassengers = item.passengers.filter(passenger => passenger.selected);

                if (selectedPassengers.length === item.passengers.length) {
                    item.allPassengers = true;
                }
            } else {
                item.allPassengers = false;
            }

            this.updateItemTotal(item);
        },
        updateItemTotal(item) {
            let selectedPassengers = item.passengers.filter(passenger => passenger.selected);

            item.adults = selectedPassengers.filter(passenger => passenger.type === 'ADULT').length;
            item.children = selectedPassengers.filter(passenger => passenger.type === 'CHILD').length;

            if (item.type === 'COLLECTIVE') {
                item.total = (parseFloat(item.adultsFare) * parseFloat(item.adults)) + (parseFloat(item.childFare) * parseFloat(item.children));

                this.businessOrder.total = 0;

                this.businessOrder.businessOrderItems.forEach(item => {
                    if (item.status !== 'DELETED') {
                        this.businessOrder.total = parseFloat(this.businessOrder.total) + parseFloat(item.total);
                    }
                });
            } else if (item.type === 'EXCLUSIVE') {
                item.total = item.serviceFare;

                this.businessOrder.total = 0;

                this.businessOrder.businessOrderItems.forEach(item => {
                    if (item.status !== 'DELETED') {
                        this.businessOrder.total = parseFloat(this.businessOrder.total) + parseFloat(item.total);
                    }
                });
            }
        },
        setCommercialName(type) {
            if (this.isCommercialNameManuallyEdited) {
                return;
            }

            let businessEntity = this.businessOrder.businessEntity;

            if (type === 'FISICA') {
                let name = businessEntity.name !== '' ? businessEntity.name : this.businessEntityQuery;

                this.businessOrder.businessEntity.commercialName = `${name} ${businessEntity.firstLastName} ${businessEntity.secondLastName}`.trim();
            } else {
                this.businessOrder.businessEntity.commercialName = businessEntity.legalName !== '' ? businessEntity.legalName : this.businessEntityQuery;
            }
        },
        setManuallyEditedFlag() {
            this.isCommercialNameManuallyEdited = this.businessOrder.businessEntity.commercialName !== '';
        }
    },
    watch: {
        'businessEntityQuery': function() {
            if (this.businessEntityQuery === '' && this.businessOrder.businessEntity.id > 0) {
                this.businessOrder.businessEntity = JSON.parse(JSON.stringify(this.businessEntity));
            }

            this.setCommercialName(this.businessOrder.businessEntity.type);
        },
        'businessOrder.businessEntity.firstLastName'() {
            this.setCommercialName(this.businessOrder.businessEntity.type);
        },
        'businessOrder.businessEntity.secondLastName'() {
            this.setCommercialName(this.businessOrder.businessEntity.type);
        },
        'businessOrder.businessEntity.legalName'() {
            this.setCommercialName(this.businessOrder.businessEntity.type);
        },
        'businessOrder.businessEntity.commercialName'() {
            if (this.businessOrder.businessEntity.commercialName === '') {
                this.isCommercialNameManuallyEdited = false;
            }
        }
    }
};
</script>

<style scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: transform 0.5s;
    }

    .fade-enter,
    .fade-leave-to {
        transform: translateX(-120%);
    }

    .fade-enter-to,
    .fade-leave {
        transform: translateX(0);
    }

    .custom-control-label {
        color: #ffffff !important;
    }
</style>