import { v4 as uuidv4 } from 'uuid';

export class BusinessEntity {
    constructor(id, name, firstLastName, secondLastName, legalName, commercialName, legalEntityType, rfc, phone1, phone2, email1, email2, type,
        isCustomer, isSupplier) {
        this.id = id;
        this.name = name;
        this.firstLastName = firstLastName;
        this.secondLastName = secondLastName;
        this.legalName = legalName;
        this.commercialName = commercialName;
        this.legalEntityType = legalEntityType;
        this.rfc = rfc;
        this.phone1 = phone1;
        this.phone2 = phone2;
        this.email1 = email1;
        this.email2 = email2;
        this.type = type;
        this.isCustomer = isCustomer;
        this.isSupplier = isSupplier;

        this.guid = uuidv4();
    }

    static create() {
        return new BusinessEntity(0, '', '', '', '', '', '', '', '', '', '', '', 'FISICA', true, false);
    }

    clone() {
        return new BusinessEntity(this.id, this.name, this.firstLastName, this.secondLastName, this.legalName, this.commercialName,
            this.legalEntityType, this.rfc, this.phone1, this.phone2, this.email1, this.email2, this.type, this.isCustomer, this.isSupplier);
    }
}