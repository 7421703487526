import { v4 as uuidv4 } from 'uuid';

export class BusinessOrderItem {
    constructor(id, name, description, type, adults, adultsFare, children, childFare, total, startDate, endDate, hotel1,
        hotel2, flightNumber, flightSchedule, notes, cancelDescription, status, passengers, allPassengers, service, duration,
        entityType, serviceFare) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.type = type;
        this.adults = adults;
        this.adultsFare = adultsFare;
        this.children = children;
        this.childFare = childFare;
        this.total = total;
        this.startDate = startDate;
        this.endDate = endDate;
        this.hotel1 = hotel1;
        this.hotel2 = hotel2;
        this.flightNumber = flightNumber;
        this.flightSchedule = flightSchedule;
        this.notes = notes;
        this.cancelDescription = cancelDescription;
        this.status = status;
        this.passengers = passengers;
        this.allPassengers = allPassengers;
        this.service = service;
        this.duration = duration;
        this.entityType = entityType;
        this.serviceFare = serviceFare;

        this.guid = uuidv4();
    }

    static create(passengers = []) {
        return new BusinessOrderItem(0, '', '', '', 0, 0.0, 0, 0.0,
            0, '', '', '', '', '', '', '', '', 'CREATED',
            passengers, true, null, '', '');
    }

    clone(passengers) {
        if (!passengers) {
            passengers = this.passengers;
        }

        return new BusinessOrderItem(0, this.name, this.description, this.type, this.adults, this.adultsFare, this.children,
            this.childFare, this.total, this.startDate, this.endDate, this.hotel1, this.hotel2, this.flightNumber, this.flightSchedule,
            this.notes, this.cancelDescription, this.status, passengers, this.allPassengers, this.service, this.duration,
            this.entityType, this.serviceFare);
    }

    isDeleted() {
        return this.status === 'DELETED';
    }

    isCancel() {
        return this.status === 'CANCEL';
    }
}