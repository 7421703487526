import { v4 as uuidv4 } from 'uuid';

export class Passenger {
    constructor(id, type, name, firstLastName, secondLastName, selected = true, assigned = false, cancel = false, serviceOrderId, identifier) {
        this.id = id;
        this.type = type;
        this.name = name;
        this.firstLastName = firstLastName;
        this.secondLastName = secondLastName;
        this.selected = selected;
        this.assigned = assigned;
        this.cancel = cancel;
        this.serviceOrderId = serviceOrderId;
        this.identifier = identifier;

        this.guid = uuidv4();
    }

    static create(type) {
        return new Passenger(0, type, '', '', '', true, false, false, 0, uuidv4());
    }

    clone() {
        return new Passenger(this.id, this.type, this.name, this.firstLastName, this.secondLastName, this.selected, this.assigned, this.cancel, this.serviceOrderId, this.identifier);
    }
}